import React, {ComponentProps, FC, useEffect} from 'react'
import {Field, FieldRenderProps, useField, useFormState} from 'react-final-form'
import { Input, InputProps } from '../../../../../ui-kit-2/inputs/input/Input'
import { getFieldError } from '../../../form-helpers/get-field-error/getFieldError'

export type InputFieldProps = InputProps & ComponentProps<typeof Field<string>>

export const InputField: FC<InputFieldProps> = (props) => {
  const { label, helperText, width, customError, ref, ...otherProps } = props

  return (
    <Field {...otherProps}>
      {(fieldRenderProps) => {
        const { input, meta, ...otherFieldRenderProps } =
          fieldRenderProps as FieldRenderProps<string>

        return (
          <Input
            label={label}
            error={getFieldError(meta) || customError}
            helperText={helperText}
            width={width}
            ref={ref}
            {...input}
            {...otherFieldRenderProps}
            onChange={e => {
              otherProps?.onChange?.(e);
              input.onChange(e);
            }}
          />
        )
      }}
    </Field>
  )
}
