import React, { FC, useEffect } from 'react'
import clsx from 'clsx'
import { defineMessages, useIntl } from 'react-intl'
import { useLocation, useNavigate } from 'react-router'
import { AuthRoutePath } from '../../../../../../config/routes/constants/authRoutePath'
import { RoutePath } from '../../../../../../config/routes/constants/routePath'
import { AuthRoute } from '../../../../../../config/routes/enums/authRoute'
import { Route } from '../../../../../../config/routes/enums/route'
import { HomeSectionAnchors } from '../../../../../../pages/home/constants/section-anchors/SectionAnchors'
import {
  ScreenDevices,
  useLessThanDeviceScreen,
} from '../../../../../../shared/lib/hooks/useLessThanDeviceScreen'
import { LandingButton } from '../../../../../../shared/ui-kit-2/inputs/landing-button/LandingButton'
import { useAppSelector } from '../../../../../../store'
import { LC } from '../../../../../../tests/e2e/locators'
import { UserHeaderMenu } from '../../../user-header/components/user-header-menu/UserHeaderMenu'
import { GuestHeaderMenuButton } from '../guest-header-menu-button/GuestHeaderMenuButton'
import styles from './styles.module.scss'

interface GuestHeaderMenuProps {
  isHeaderOnTop: boolean
}

const GuestHeaderMenuMessages = defineMessages({
  features: {
    id: 'GuestHeaderMenuMessages.features',
    defaultMessage: 'Features',
  },
  product: {
    id: 'GuestHeaderMenuMessages.product',
    defaultMessage: 'Product',
  },
  pricing: {
    id: 'GuestHeaderMenuMessages.pricing',
    defaultMessage: 'Pricing',
  },
  contact: {
    id: 'GuestHeaderMenuMessages.contact',
    defaultMessage: 'Contact',
  },
  signIn: {
    id: 'GuestHeaderMenuMessages.signIn',
    defaultMessage: 'Log in',
  },
  signUp: {
    id: 'GuestHeaderMenuMessages.signUp',
    defaultMessage: 'Register',
  },
})

export const GuestHeaderMenu: FC<GuestHeaderMenuProps> = ({
  isHeaderOnTop,
}) => {
  const { isAuthenticated } = useAppSelector((state) => state.user)
  const { pathname, hash } = useLocation()
  const navigate = useNavigate()
  const intl = useIntl()

  const toHome = (anchor: string) => navigate(RoutePath[Route.Home] + anchor)
  const toSignIn = () => navigate(AuthRoutePath[AuthRoute.SignIn])
  const toSignUp = () => navigate(AuthRoutePath[AuthRoute.SignUp])

  useEffect(() => {
    if (pathname === RoutePath[Route.Home]) {
      const anchorElement = document.getElementById(hash.replace('#', ''))
      if (anchorElement) anchorElement.scrollIntoView()
    }
  }, [pathname, hash])

  const UnAuthorizedActions = () => (
    <>
      <LandingButton
        text={intl.formatMessage(GuestHeaderMenuMessages.signIn)}
        onClick={toSignIn}
        data-test-id={LC.HEADER.SIGN_IN_BTN}
        variant={'greenTextOutlined'}
      />

      <LandingButton
        text={intl.formatMessage(GuestHeaderMenuMessages.signUp)}
        onClick={toSignUp}
        data-test-id={LC.HEADER.SIGN_UP_BTN}
      />
    </>
  )

  const AuthorizedActions = () => <UserHeaderMenu />

  return (
    <div className={styles.Container}>
      <div
        className={clsx(
          styles.Menu,
          isHeaderOnTop && styles.Menu_isHeaderOnTop,
        )}
      >
        <GuestHeaderMenuButton
          variant={'text'}
          onClick={() => toHome(HomeSectionAnchors.FEATURES)}
          isHeaderOnTop={isHeaderOnTop}
        >
          {intl.formatMessage(GuestHeaderMenuMessages.features)}
        </GuestHeaderMenuButton>
        <GuestHeaderMenuButton
          variant={'text'}
          onClick={() => toHome(HomeSectionAnchors.PRODUCT)}
          isHeaderOnTop={isHeaderOnTop}
        >
          {intl.formatMessage(GuestHeaderMenuMessages.product)}
        </GuestHeaderMenuButton>
        <GuestHeaderMenuButton
          variant={'text'}
          onClick={() => toHome(HomeSectionAnchors.PRICING)}
          isHeaderOnTop={isHeaderOnTop}
        >
          {intl.formatMessage(GuestHeaderMenuMessages.pricing)}
        </GuestHeaderMenuButton>
        <GuestHeaderMenuButton
          variant={'text'}
          onClick={() => toHome(HomeSectionAnchors.CONTACT)}
          isHeaderOnTop={isHeaderOnTop}
        >
          {intl.formatMessage(GuestHeaderMenuMessages.contact)}
        </GuestHeaderMenuButton>
      </div>

      {isAuthenticated ? <AuthorizedActions /> : <UnAuthorizedActions />}
    </div>
  )
}
