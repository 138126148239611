import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import emptyRedirectionsImg from '../../../assets/images/empty-redirections.png'
import styles from './styles.module.scss'

const EmptyCallRedirectionsMessages = defineMessages({
  title: {
    id: 'EmptyCallRedirectionsMessages.title',
    defaultMessage: 'Flexible redirect settings',
  },
  description: {
    id: 'EmptyCallRedirectionsMessages.description',
    defaultMessage:
      'Set up receiving messages about SMS from the numbers you need to your Email or Telegram',
  },
})

export const EmptySmsRedirections = () => {
  const intl = useIntl()

  return (
    <div className={styles.Container}>
      <div className={styles.Content}>
        <div className={styles.Title}>
          {intl.formatMessage(EmptyCallRedirectionsMessages.title)}
        </div>
        <div className={styles.Description}>
          {intl.formatMessage(EmptyCallRedirectionsMessages.description)}
        </div>
      </div>

      <img
        src={emptyRedirectionsImg}
        alt="Redirections"
        className={styles.Image}
      />
    </div>
  )
}
