import React, { useMemo } from 'react'
import { Form } from 'react-final-form'
import { defineMessages, useIntl } from 'react-intl'
import { useAddContact } from '../_hooks/use-add-contact/useAddContact'
import { useModal } from '../../../app/hooks/useModal'
import { useSnackbar } from '../../../app/hooks/useSnackbar'
import { ReactComponent as TrashIcon } from '../../../assets/icons/trash.svg'
import { dongleCallCreateDatagate } from '../../../sdk/datagates/api/dongle'
import { useBackendErrorCodes } from '../../../sdk/datagates/helpers/_common/use-backend-error-codes'
import { DongleCallCreateRequest } from '../../../sdk/datagates/types/dongle/call/_crud/create'
import { formatPhoneNumber } from '../../../sdk/formatters/format-phone-number'
import { useDevices } from '../../../sdk/hooks/use-devices/useDevices'
import { SelectSearchField } from '../../../shared/lib/form/form-field-adapters/v2/select-search-field/SelectSearchField'
import { PhoneInputField } from '../../../shared/lib/form/form-field-templates/v2/phone-input-field/PhoneInputField'
import { clearPhoneMutator } from '../../../shared/lib/form/form-helpers/mutators'
import { useFormRules } from '../../../shared/lib/form/form-rules'
import { getErrorMessage } from '../../../shared/lib/utils/get-error-message/getErrorMessage'
import { getPhoneString } from '../../../shared/lib/utils/libphonenumber/create-phone-string'
import { Drawer } from '../../../shared/ui-kit-2/data-display/drawer/Drawer'
import { Button } from '../../../shared/ui-kit-2/inputs/button/Button'
import { Input } from '../../../shared/ui-kit-2/inputs/input/Input'
import { SelectSearchOption } from '../../../shared/ui-kit-2/inputs/select-search/SelectSearch'
import { TextButton } from '../../../shared/ui-kit-2/inputs/text-button/TextButton'
import { LoadingContent } from '../../loading-content/LoadingContent'
import { SNACKBAR_TYPES } from '../../snackbar/SnackbarProvider'
import styles from './styles.module.scss'
import {
  PhoneInputFieldNumber
} from "../../../shared/lib/form/form-field-templates/v2/phone-input-field-new/PhoneInputFieldNew";
import {getClearedPhoneNumber} from "../../../shared/lib/utils/get-cleared-phone-number/getClearedPhoneNumber";

type CreateCallFormType = {
  dongleId: number
  phone?: string
}

const CreateCallModalMessages = defineMessages({
  title: {
    id: 'CreateCallModalMessages.title',
    defaultMessage: 'Create call',
  },
  call: {
    id: 'CreateCallModalMessages.call',
    defaultMessage: 'Call',
  },
  deviceLabel: {
    id: 'CreateCallModalMessages.deviceLabel',
    defaultMessage: 'Device',
  },
  devicePlaceholder: {
    id: 'CreateCallModalMessages.devicePlaceholder',
    defaultMessage: 'Select device',
  },
  phoneLabel: {
    id: 'CreateCallModalMessages.phoneLabel',
    defaultMessage: 'Phone',
  },
  contacts: {
    id: 'CreateCallModalMessages.contacts',
    defaultMessage: 'Contacts',
  },
  contactLabel: {
    id: 'CreateCallModalMessages.contactLabel',
    defaultMessage: 'Contact',
  },
  positiveAdded: {
    id: 'CreateCallModalMessages.positiveAdded',
    defaultMessage: 'Call is successfully created',
  },
  negativeAdded: {
    id: 'CreateCallModalMessages.negativeAdded',
    defaultMessage: 'An error occurred while call',
  },
})

export const CreateCallModal = () => {
  const { handleHideModal, props } = useModal()

  const { handleOpenSnackbar } = useSnackbar()
  const intl = useIntl()
  const { resolveBackendError } = useBackendErrorCodes()
  const { ruleRequired } = useFormRules()
  const { devices, loading } = useDevices({ page: 0, limit: 0, takeAll: true })
  const dongleOptions: SelectSearchOption[] = useMemo(
    () =>
      devices.map((d) => {
        return {
          key: d.dongleId,
          label: d.name,
          inputLabel: d.name,
          value: d.dongleId.toString(),
        }
      }),
    [devices],
  )

  const {
    component: contactSelectComponent,
    setIsOpen: setIsContactSelectOpen,
    savedContact,
    removeContact,
  } = useAddContact()

  const deviceId = props?.deviceId

  const handleAddCall = async (values: CreateCallFormType) => {
    try {
      const urlParams = [
        { name: 'dongle_id', value: values.dongleId.toString() },
      ]

      const reqBody: DongleCallCreateRequest = {}

      if (savedContact) reqBody.contact_id = savedContact.contactId
      else
        reqBody.number = getClearedPhoneNumber(values.phone)

      await dongleCallCreateDatagate(reqBody, urlParams)

      props?.handleFetch && (await props.handleFetch())
      props?.handleFetchDevices && (await props.handleFetchDevices())

      handleHideModal()
      handleOpenSnackbar({
        type: SNACKBAR_TYPES.success,
        text: intl.formatMessage(CreateCallModalMessages.positiveAdded),
      })
    } catch (e) {
      handleOpenSnackbar({
        type: SNACKBAR_TYPES.error,
        text: resolveBackendError(
          getErrorMessage(e),
          intl.formatMessage(CreateCallModalMessages.negativeAdded),
        ),
      })
    }
  }

  const openContactsButton = (
    <TextButton
      text={intl.formatMessage(CreateCallModalMessages.contacts)}
      icon={'contact'}
      onClick={() => setIsContactSelectOpen(true)}
    />
  )

  const content = (
    <Form
      onSubmit={handleAddCall}
      initialValues={{
        dongleId: deviceId,
      }}
      mutators={{
        clearPhoneMutator,
      }}
      render={({ handleSubmit, submitting, values, form }) => {
        return (
          <form onSubmit={handleSubmit} className={styles.Container}>
            <SelectSearchField
              name="dongleId"
              validate={ruleRequired()}
              options={dongleOptions}
              label={intl.formatMessage(CreateCallModalMessages.deviceLabel)}
              placeholder={intl.formatMessage(
                CreateCallModalMessages.devicePlaceholder,
              )}
              markAsRequired={true}
            />

            {savedContact ? (
              <Input
                value={`${savedContact.name}: ${formatPhoneNumber(savedContact.phone)}`}
                label={intl.formatMessage(CreateCallModalMessages.contactLabel)}
                readOnly={true}
                postfix={
                  <TrashIcon
                    onClick={removeContact}
                    className={styles.DeleteContact}
                  />
                }
                markAsRequired={true}
              />
            ) : (
              <PhoneInputFieldNumber
                name="phone"
                label={intl.formatMessage(CreateCallModalMessages.phoneLabel)}
                markAsRequired={true}
                postfix={openContactsButton}
              />
            )}

            <Button
              type={'submit'}
              text={intl.formatMessage(CreateCallModalMessages.call)}
              additionalClassNames={[styles.SubmitBtn]}
              disabled={submitting}
              loading={submitting}
            />
          </form>
        )
      }}
    />
  )

  return (
    <Drawer
      title={intl.formatMessage(CreateCallModalMessages.title)}
      isOpen={true}
      close={handleHideModal}
      additionalClassNames={[styles.DrawerAdditional]}
    >
      <LoadingContent loading={loading} content={content} />

      {contactSelectComponent}
    </Drawer>
  )
}
