import React, { FC } from 'react'
import clsx from 'clsx'
import { ReactComponent as ArrowLeftIcon } from './assets/icons/arrow-left.svg'
import { ReactComponent as ArrowRightIcon } from './assets/icons/arrow-right.svg'
import { ReactComponent as EditIcon } from './assets/icons/edit.svg'
import { ReactComponent as FilterIcon } from './assets/icons/filter.svg'
import { ReactComponent as MinusIcon } from './assets/icons/minus.svg'
import { ReactComponent as PlusIcon } from './assets/icons/plus.svg'
import { ReactComponent as SettingsIcon } from './assets/icons/settings.svg'
import { ReactComponent as TrashIcon } from './assets/icons/trash.svg'
import styles from './styles.module.scss'

const ICON_BUTTON_ICON_LIST = {
  trash: 'trash',
  filter: 'filter',
  settings: 'settings',
  edit: 'edit',
  arrowLeft: 'arrowLeft',
  arrowRight: 'arrowRight',
  plus: 'plus',
  minus: 'minus',
} as const

const ICONS = {
  [ICON_BUTTON_ICON_LIST.trash]: <TrashIcon />,
  [ICON_BUTTON_ICON_LIST.filter]: <FilterIcon />,
  [ICON_BUTTON_ICON_LIST.settings]: <SettingsIcon />,
  [ICON_BUTTON_ICON_LIST.edit]: <EditIcon />,
  [ICON_BUTTON_ICON_LIST.arrowLeft]: <ArrowLeftIcon />,
  [ICON_BUTTON_ICON_LIST.arrowRight]: <ArrowRightIcon />,
  [ICON_BUTTON_ICON_LIST.plus]: <PlusIcon />,
  [ICON_BUTTON_ICON_LIST.minus]: <MinusIcon />,
}

const ICON_BUTTON_VARIANT_LIST = {
  outlined: 'outlined',
  filled: 'filled',
} as const

interface IconButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  icon: (typeof ICON_BUTTON_ICON_LIST)[keyof typeof ICON_BUTTON_ICON_LIST]
  variant?: (typeof ICON_BUTTON_VARIANT_LIST)[keyof typeof ICON_BUTTON_VARIANT_LIST]
  additionalClassNames?: string[]
  testId?: string
}

export const IconButton: FC<IconButtonProps> = (props) => {
  const {
    icon,
    variant = 'filled',
    additionalClassNames = [],
    testId,
    ...otherProps
  } = props

  return (
    <button
      type={'button'}
      className={clsx(
        styles.Button,
        variant === 'filled' && styles.Button_filled,
        variant === 'outlined' && styles.Button_outlined,
        ...additionalClassNames,
      )}
      data-test-id={testId}
      {...otherProps}
    >
      {ICONS[icon]}
    </button>
  )
}
