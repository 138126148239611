import React from 'react';
import {DeviceDetailsModalMessages} from "../../messages/DeviceDetailsModalMessages";
import {Input} from "../../../../../shared/ui-kit-2/inputs/input/Input";
import {Button} from "../../../../../shared/ui-kit-2/inputs/button/Button";
import {
  CommonButtonMessages
} from "../../../../../config/intl/common-messages/common-button-messages/CommonButtonMessages";
import {Card} from "../../../../../shared/ui-kit-2/data-display/card/Card";
import {useIntl} from "react-intl";
import styles from './styles.module.scss';
import clsx from "clsx";
import {Sticker} from "../../../../../shared/ui-kit-2/data-display/sticker/Sticker";

interface UssdCardProps {
  isTariffPackageEnabled: boolean
}

export const UssdCard = (props: UssdCardProps) => {
  const {isTariffPackageEnabled} = props;
  const intl = useIntl();

  return (
    <Card additionalClassNames={[clsx(styles.UssdCard, isTariffPackageEnabled && styles.ErrorSubscription)]}>
      <div className={styles.UssdTitle}>
        <div className={styles.UssdTitle__text}>
          {intl.formatMessage(DeviceDetailsModalMessages.ussdTitle)}
        </div>
        <Sticker color={'greenOutline'} text={intl.formatMessage(DeviceDetailsModalMessages.soon)}/>
      </div>

      <div className={styles.UssdFakeForm}>
        <Input
          placeholder={intl.formatMessage(
            DeviceDetailsModalMessages.ussdLabel,
          )}
          disabled={true}
          inputSize={'small'}
          additionalClassNames={[styles.UssdInput]}
        />
        <Button
          text={intl.formatMessage(CommonButtonMessages.send)}
          disabled={true}
          variant={'greenTextOutlined'}
          size={'small'}
          additionalClassNames={[styles.UssdSubmitBtn]}
        />
      </div>
    </Card>
  );
};

