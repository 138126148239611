import React from 'react'
import { useNavigate } from 'react-router'
import { useSiteVersion } from '../../../../app/hooks/useSiteVersion'
import { ReactComponent as JivoChatCustomIcon } from '../../../../assets/icons/jivo-chat-icon.svg'
import { ReactComponent as LogoVector } from '../../../../assets/icons/logo.svg'
import { RoutePath } from '../../../../config/routes/constants/routePath'
import { Route } from '../../../../config/routes/enums/route'
import { UserHeaderMobileMenu } from './components/user-mobile-header-menu/UserHeaderMobileMenu'
import styles from './styles.module.scss'

export const UserMobileHeader = () => {
  const navigate = useNavigate()
  const { siteVersion } = useSiteVersion()

  const toHomePage = () => {
    navigate(RoutePath[Route.Home])
  }
  const handleOpenChat = () => {
    //@ts-ignore
    if (window.jivo_api) {
      //@ts-ignore
      window.jivo_api.open()
    }
  }

  return (
    <div className={styles.Container}>
      <LogoVector className={styles.Logo} onClick={toHomePage} />
      <div className={styles.Actions}>
        {siteVersion !== 'ru' && (
          <div className={styles.JivoChat} onClick={handleOpenChat}>
            <JivoChatCustomIcon tabIndex={0} />
          </div>
        )}
        <UserHeaderMobileMenu />
      </div>
    </div>
  )
}
