import React from 'react'
import { PageWrapper } from '../../../../layouts/page-wrapper/PageWrapper'
import { useDevices } from '../../../../sdk/hooks/use-devices/useDevices'
import {
  ScreenDevices,
  useLessThanDeviceScreen,
} from '../../../../shared/lib/hooks/useLessThanDeviceScreen'
import { CallsListDesktopPage } from './components/callsListDesktopPage/CallsListDesktopPage'
import { CallsListMobilePage } from './components/callsListMobilePage/CallsListMobilePage'

export const CallListPage = () => {
  const isLaptopL = useLessThanDeviceScreen(ScreenDevices.LAPTOP_L)

  const { devices, loading } = useDevices({
    page: 0,
    limit: 0,
    takeAll: true,
    isActive: true,
  })

  return (
    <PageWrapper>
      {isLaptopL ? (
        <CallsListMobilePage devices={devices} activeDevicesLoading={loading} />
      ) : (
        <CallsListDesktopPage devices={devices} loading={loading} />
      )}
    </PageWrapper>
  )
}
