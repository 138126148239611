import { defineMessages, useIntl } from 'react-intl'
import {
  ScreenDevices,
  useLessThanDeviceScreen,
} from '../../../../../../../../shared/lib/hooks/useLessThanDeviceScreen'
import { Button } from '../../../../../../../../shared/ui-kit-2/inputs/button/Button'
import { Card } from '../../../profilePageEn/components/card/Card'
import { ReactComponent as BankCardIcon } from './assets/bank-cards.svg'
import styles from './styles.module.scss'

const BankCardsMessages = defineMessages({
  title: {
    id: 'BankCardsMessages.title',
    defaultMessage: 'Bank Cards',
  },
  newPaymentMethod: {
    id: 'BankCardsMessages.addCard',
    defaultMessage: 'Add Card',
  },
})

export const BankCards = () => {
  const intl = useIntl()

  return (
    <Card noPadding={{ all: true }}>
      <div className={styles.Container}>
        <div className={styles.Title}>
          {intl.formatMessage(BankCardsMessages.title)}
        </div>
      </div>
      <div className={styles.DevelopInfo}>В разработке</div>
      <div className={styles.BankCardsIcon}>
        <BankCardIcon />
      </div>
    </Card>
  )
}
