import React, { FC, ReactNode, useEffect, useState } from 'react'
import clsx from 'clsx'
import styles from './styles.module.scss'

export type CloudTabType = {
  id: string
  label: string
  render?: ReactNode
  onClick?: () => void
  testId?: string
}

type CloudTabsType = Array<CloudTabType>

interface CloudTabsProps {
  items: CloudTabsType
  defaultTabId?: string
  handleChange?: (id: string) => void
  additionalClassNames?: string[]
}

export const CloudTabs: FC<CloudTabsProps> = ({
  items,
  defaultTabId,
  handleChange = () => null,
  additionalClassNames = [],
  ...props
}) => {
  const getTabById = (foundedId: string) =>
    items.find(({ id }) => id === foundedId) || items[0]

  const [selectedTab, setSelectedTab] = useState<CloudTabType>(
    defaultTabId ? getTabById(defaultTabId) : items[0],
  )

  const handleChangeTab = (id: string) => {
    handleChange(id)
    setSelectedTab(getTabById(id))
  }

  useEffect(() => {
    setSelectedTab(getTabById(selectedTab.id))
  }, [items, selectedTab.id])

  return (
    <div {...props} className={styles.CloudTabsWrapper}>
      <div className={clsx(styles.CloudTabs, ...additionalClassNames)}>
        {items?.map(({ id, label, testId }) => (
          <div
            key={id}
            className={clsx(
              styles.CloudTab,
              selectedTab.id === id && styles.CloudTab_selected,
            )}
            onClick={() => handleChangeTab(id)}
            data-test-id={testId}
          >
            {label}
          </div>
        ))}
      </div>
      {selectedTab?.render}
    </div>
  )
}
