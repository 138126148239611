import React, { useState } from 'react';
import styles from './styles.module.scss';
import { defineMessages, useIntl } from "react-intl";
import { Switcher } from "../../../../../../../../shared/ui-kit-2/inputs/switcher/Switcher";
import { useAppSelector } from "../../../../../../../../store";
import { UserAutoRenewal } from "../../../../../../../../store/reducers/user/types";
import { userUpdateDatagate } from "../../../../../../../../sdk/datagates/api/user";
import { SNACKBAR_TYPES } from "../../../../../../../../layouts/snackbar/SnackbarProvider";
import { getErrorMessage } from "../../../../../../../../shared/lib/utils/get-error-message/getErrorMessage";
import { useBackendErrorCodes } from "../../../../../../../../sdk/datagates/helpers/_common/use-backend-error-codes";
import { useSnackbar } from "../../../../../../../../app/hooks/useSnackbar";

const AutoRenewalMessages = defineMessages({
  title: {
    id: 'AutoRenewalMessages.title',
    defaultMessage: 'Auto-renewal subscription',
  },
  switcherFromBalance: {
    id: 'AutoRenewalMessages.switcherFromBalance',
    defaultMessage: 'From Balance',
  },
  switcherFromBankCard: {
    id: 'AutoRenewalMessages.switcherFromBankCard',
    defaultMessage: 'From Bank Card',
  },
  error: {
    id: 'AutoRenewalMessages.error',
    defaultMessage: 'An error occurred when changing the auto-subscription type.',
  }
})

export const AutoRenewal = () => {
  const intl = useIntl();
  const { paymentTypeId } = useAppSelector((state) => state.user);
  const [activeAutoSubscription, setActiveAutoSubscription] = useState<UserAutoRenewal>(paymentTypeId || UserAutoRenewal.FROM_BALANCE);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const { resolveBackendError } = useBackendErrorCodes();
  const { handleOpenSnackbar } = useSnackbar();

  const handleChange = async (typeSubscription: UserAutoRenewal) => {
    setIsDisabled(true);
    try {
      await userUpdateDatagate({
        user_payment_type_id: typeSubscription,
      });
      setActiveAutoSubscription(typeSubscription);
      setIsDisabled(false);
    } catch (e) {
      handleOpenSnackbar({
        type: SNACKBAR_TYPES.error,
        text: resolveBackendError(
          getErrorMessage(e),
          intl.formatMessage(AutoRenewalMessages.error),
        ),
      });
      setIsDisabled(false);
    }
  };

  return (
    <div className={styles.AutoRenewalWrapper}>
      <div className={styles.Title}>
        {intl.formatMessage(AutoRenewalMessages.title)}
      </div>
      <div className={styles.AutoRenewalSwitchers}>
        <Switcher
          checked={activeAutoSubscription === UserAutoRenewal.FROM_BALANCE}
          value={UserAutoRenewal.FROM_BALANCE}
          label={intl.formatMessage(AutoRenewalMessages.switcherFromBalance)}
          disabled={isDisabled}
          onChange={() => handleChange(UserAutoRenewal.FROM_BALANCE)}
        />
        <Switcher
          checked={activeAutoSubscription === UserAutoRenewal.FROM_BANK_CARD}
          value={UserAutoRenewal.FROM_BANK_CARD}
          label={intl.formatMessage(AutoRenewalMessages.switcherFromBankCard)}
          disabled={isDisabled}
          onChange={() => handleChange(UserAutoRenewal.FROM_BANK_CARD)}
        />
      </div>
    </div>
  );
};
