import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import noDevicesForCallsImg from '../../../assets/images/no-devices-for-calls.png'
import { BuyDeviceButton } from '../../custom-buttons/buy-device-button/BuyDeviceButton'
import styles from './styles.module.scss'

const NoDevicesForCallsMessages = defineMessages({
  title: {
    id: 'NoDevicesForCallsMessages.title',
    defaultMessage: 'Buy Teleсorn modem to receive and make calls',
  },
})

export const NoDevicesForCalls = () => {
  const intl = useIntl()

  return (
    <div className={styles.Container}>
      <div className={styles.Title}>
        {intl.formatMessage(NoDevicesForCallsMessages.title)}
      </div>
      <BuyDeviceButton additionalClassNames={[styles.Btn]} />

      <img
        src={noDevicesForCallsImg}
        alt="Buy new device"
        className={styles.Image}
      />
    </div>
  )
}
