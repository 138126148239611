// Locators for testing
export const LC = {
  HEADER: {
    _: 'header',
    SIGN_IN_BTN: 'header-sign-in-btn',
    SIGN_UP_BTN: 'header-sign-up-btn',
    DEVICES_BTN: 'header-devices-btn',
    CALLS_BTN: 'header-calls-btn',
    SMS_BTN: 'header-sms-btn',
    CONTACTS_BTN: 'header-contacts-btn',
    WIFI_BTN: 'header-wifi-btn',
    BUY_NEW_DEVICE_BTN: 'header-buy-new-device-btn',
    BALANCE_BTN: 'header-balance-btn',
    CART_BTN: 'header-cart-btn',
    MENU_BTN: 'header-menu-btn',
    MENU_PROFILE: 'header-menu-profile',
    MENU_BILLING_HISTORY: 'header-menu-billing-history',
    MENU_ORDERS: 'header-menu-orders',
    MENU_CARDS: 'header-menu-cards',
    MENU_API: 'header-menu-api',
    MENU_EDIT_PASSWORD: 'header-menu-edit-password',
    MENU_LOGOUT: 'header-menu-logout',
  },
  SIGN_IN_PAGE: {
    _: 'sign-in-page',
    GOOGLE_BTN: 'sign-in-page-google-btn',
    EMAIL_INPUT: 'sign-in-page-email-input',
    PASSWORD_INPUT: 'sign-in-page-password-input',
    FORGOT_PASSWORD_LINK: 'sign-in-page-forgot-password-link',
    SUBMIT_BTN: 'sign-in-page-submit-btn',
    SIGN_UP_LINK: 'sign-in-page-sign-up-link',
  },
  SIGN_UP_PAGE: {
    _: 'sign-up-page',
    EMAIL_INPUT: 'sign-up-page-email-input',
    PASSWORD_INPUT: 'sign-up-page-password-input',
    REPEAT_PASSWORD_INPUT: 'sign-up-page-repeat-password-input',
    TERMS_CHECKBOX: 'sign-up-page-terms-checkbox',
    SUBMIT_BTN: 'sign-up-page-submit-btn',
    SIGN_IN_LINK: 'sign-up-page-sign-in-link',
  },
  FORGOT_PASSWORD_PAGE: {
    _: 'forgot-password-page',
    EMAIL_INPUT: 'forgot-password-page-email-input',
    SUBMIT_BTN: 'forgot-password-page-submit-btn',
    RESEND_BTN: 'forgot-password-page-resend-btn',
    VERIFICATION_CODE: (idx: number) => `forgot-password-digit-${idx}`,
  },
  SET_NEW_PASSWORD_PAGE: {
    _: 'set-new-password-page',
    PASSWORD: 'set-new-password-page-password',
    REPEAT_PASSWORD: 'set-new-password-page-repeat-password',
    SUBMIT_BTN: 'set-new-password-page-submit-btn',
  },
  DEVICES: {
    _: 'devices',
    TABLE: {
      _: 'devices-table',
      ROW: {
        ID: (idx: number) => `devices-table-row-${idx}-id`,
        DEVICE: (idx: number) => `devices-table-row-${idx}-device`,
        DATE: (idx: number) => `devices-table-row-${idx}-date`,
        SMS: (idx: number) => `devices-table-row-${idx}-sms`,
        CALLS: (idx: number) => `devices-table-row-${idx}-calls`,
        IS_ONLINE: (idx: number) => `devices-table-row-${idx}-is-online`,
        PHONE_NUMBER: (idx: number) => `devices-table-row-${idx}-phone-number`,
        ACTIONS: {
          _: (idx: number) => `devices-table-row-${idx}-actions`,
          VIEW_DEVICE_BTN: (idx: number) =>
            `devices-table-row-${idx}-actions-view-device-btn`,
          DEVICE_MENU_BTN: {
            _: (idx: number) =>
              `devices-table-row-${idx}-actions-device-menu-btn`,
            EDIT: (idx: number) =>
              `devices-table-row-${idx}-actions-device-menu-btn-edit`,
            SMS: (idx: number) =>
              `devices-table-row-${idx}-actions-device-menu-btn-sms`,
            CALL: (idx: number) =>
              `devices-table-row-${idx}-actions-device-menu-btn-call`,
            DELETE: (idx: number) =>
              `devices-table-row-${idx}-actions-device-menu-btn-delete`,
          },
        },
      },
    },
  },
  DEVICE_PAGE: {
    _: 'device-page',
    SETTINGS: {
      BTN: 'device-page-settings-btn',
      EDIT: 'device-page-settings-edit',
      CHANGE_SUBSCRIPTION: 'device-page-settings-change-subscription',
      DELETE: 'device-page-settings-delete',
    },
    USSD: {
      _: 'device-page-ussd',
      INPUT: 'device-page-ussd-input',
      SUBMIT: 'device-page-ussd-submit',
    },
  },
  CALLS: {
    _: 'calls',
    NEW: 'calls-new',
    TYPE: {
      ALL: 'calls-type-all',
      INCOMING: 'calls-type-incoming',
      OUTGOING: 'calls-type-outgoing',
      DELAYED: 'calls-type-delayed',
    },
    FILTER: {
      BTN: 'call-filter-btn',
      CLEAR_ALL_BTN: 'call-filter-clear-all-btn',
      SUBMIT_BTN: 'call-filter-submit-btn',
      DATEPICKER_FROM: 'call-filter-datepicker-from',
      DATEPICKER_TO: 'call-filter-datepicker-to',
      DEVICES_MULTISELECT: 'call-devices-multiselect-filter',
    },
    TABLE: {
      _: 'calls-table',
      ROW: {
        DATE: (idx: number) => `calls-table-row-${idx}-date`,
        DEVICE: (idx: number) => `calls-table-row-${idx}-device`,
        SENDER: (idx: number) => `calls-table-row-${idx}-sender`,
        RECEIVER: (idx: number) => `calls-table-row-${idx}-receiver`,
        ACTIONS: {
          _: (idx: number) => `calls-table-row-${idx}-actions`,
          DELETE: (idx: number) => `calls-table-row-${idx}-actions-delete`,
        },
      },
    },
  },
  SMS: {
    _: 'sms',
    NEW: 'sms-page-send-sms-button',
    TYPE: {
      ALL: 'sms-type-all',
      INCOMING: 'sms-type-incoming',
      OUTGOING: 'sms-type-outgoing',
      DELAYED: 'sms-type-delayed',
    },
    FILTER: {
      BTN: 'sms-filter-btn',
      SUBMIT_BTN: 'sms-filter-submit-btn',
      CLEAR_ALL_BTN: 'sms-filter-clear-all-btn',
      DATEPICKER_FROM: 'sms-filter-datepicker-from',
      DATEPICKER_TO: 'sms-filter-datepicker-to',
      TEXT_AREA: 'sms-filter-text-area',
      DEVICES_MULTISELECT: 'sms-devices-multiselect-filter',
      SWITCHER_PHONE_NUMBER: 'sms-filter-phone-number-switcher',
      RADIO_GROUPS: {
        INTERNATIONAL: 'sms-filter-radio-group-international',
        SHORT: 'sms-filter-radio-group-short',
        ALPHA: 'sms-filter-radio-group-alpha',
      },
      PHONE_INPUT: {
        REGION: 'sms-filter-phone-region',
        NATIONAL: 'sms-filter-phone-national',
        SHORT: 'sms-filter-phone-short',
      },
    },
    TABLE: {
      _: 'sms-table',
      ROW: {
        _: (idx: number) => `sms-table-row-main-${idx}`,
        DEVICE_SENDER: (idx: number) => `sms-table-row-${idx}-device-sender`,
        DEVICE_RECEIVER: (idx: number) =>
          `sms-table-row-${idx}-device-receiver`,
        SENDER: (idx: number) => `sms-table-row-${idx}-caller-number`,
        RECEIVER: (idx: number) => `sms-table-row-${idx}-receiver-number`,
        STATUS: (idx: number) => `sms-table-row-${idx}-status`,
        DATE: (idx: number) => `sms-table-row-${idx}-date`,
        TEXT: (idx: number) => `sms-table-row-${idx}-text`,
        ACTIONS: {
          _: (idx: number) => `sms-table-row-${idx}-actions`,
          REPLY: (idx: number) => `sms-table-row-${idx}-actions-reply`,
          INFO: (idx: number) => `sms-table-row-${idx}-actions-info`,
          DELETE: (idx: number) => `sms-table-row-${idx}-actions-delete`,
        },
      },
    },
    MODAL: {
      MULTISELECT_DEVICE: 'send-sms-modal-device-select',
      INPUT_PHONE: 'send-sms-modal-phone-number-input',
      INPUT_PHONE_COUNTRY: 'send-sms-modal-phone-country-select',
      DATEPICKER: 'send-sms-modal-datepicker-select',
      TEXTAREA: 'send-sms-modal-text-message-textarea',
      SEND_BTN: 'send-sms-modal-send-sms-button',
    },
  },
  MULTISELECT: {
    OPTION: (optionNumber: number) => `multiselect-option-${optionNumber}`,
    SWITCHER: 'multiselect-switcher',
  },
  WIFI: {
    _: 'wifi',
    CARDS: {
      _: 'wifi-cards',
      EDIT_BTN: (idx: number) => `wifi-cards-element-${idx}-edit-btn`,
      STATE_SWITCHER: (idx: number) =>
        `wifi-cards-element-${idx}-state-switcher`,
    },
  },
  REDIRECTIONS: {
    _: 'redirections',
    NEW: 'redirections-new',
    MODAL: {
      SMS: 'sms',
      CAll: 'call',
      INPUTS: {
        NAME: 'redirection-name',
        DEVICES_SELECT: 'redirection-devices',
        INCOMING_PHONE_NUMBER: 'redirection-incoming-phone-number',
        EMAIL: 'redirection-email',
        TELEGRAM: 'redirection-telegram',
      },
      DELETE_BTN: {
        INCOMING_PHONE_NUMBER: 'redirection-incoming-phone-number-delete-btn',
        EMAIL: 'redirection-email-delete-btn',
        TELEGRAM: 'redirection-telegram-delete-btn',
      },
      ADD_BTN: {
        INCOMING_PHONE_NUMBER: 'redirection-incoming-phone-number-add-btn',
        EMAIL: 'redirection-email-add-btn',
        TELEGRAM: 'redirection-telegram-add-btn',
      },
      SUBMIT_BTN: 'redirection-submit-modal-btn',
    },
    TABLE: {
      _: 'redirections-table',
      ROW: {
        NAME: (idx: number) => `redirections-table-row-${idx}-name`,
        TYPE: (idx: number) => `redirections-table-row-${idx}-type`,
        DEVICES: (idx: number) => `redirections-table-row-${idx}-devices`,
        PHONES: (idx: number) => `redirections-table-row-${idx}-phones`,
        TO: (idx: number) => `redirections-table-row-${idx}-to`,
        MENU: {
          _: (idx: number) => `redirections-table-row-${idx}-menu`,
          EDIT: (idx: number) => `redirections-table-row-${idx}-menu-edit`,
          DELETE: (idx: number) => `redirections-table-row-${idx}-menu-delete`,
        },
      },
    },
  },
  NEW_CALL: {
    _: 'new-call',
    DEVICE_SELECT: 'new-call-device-select',
    USE_SHORT_NUMBER_SWITCHER: 'new-call-use-short-number-switcher',
    PHONE_REGION: 'new-call-phone-region',
    PHONE_NATIONAL_NUMBER: 'new-call-phone-national-number',
    PHONE_SHORT: 'new-call-phone-short',
    SUBMIT_BTN: 'new-call-submit-btn',
  },
  NEW_SMS: {
    _: 'new-sms',
    DEVICE_SELECT: 'new-sms-device-select',
    USE_SHORT_NUMBER_SWITCHER: 'new-sms-use-short-number-switcher',
    PHONE_REGION: 'new-sms-phone-region',
    PHONE_NATIONAL_NUMBER: 'new-sms-phone-national-number',
    PHONE_SHORT: 'new-sms-phone-short',
    MESSAGE: 'new-sms-message',
    SUBMIT_BTN: 'new-sms-submit-btn',
  },
  WIFI_SETTINGS: {
    _: 'wifi-settings',
    NAME: 'wifi-settings-name',
    PASSWORD: 'wifi-settings-password',
    SUBMIT: 'wifi-settings-submit',
    CANCEL: 'wifi-settings-cancel',
  },
  DEVICE_SETTINGS: {
    _: 'device-settings',
    NAME: 'device-settings-name',
    SUBMIT: 'device-settings-submit',
  },
  BALANCE_HISTORY: {
    CURRENT_BALANCE_TEXT: 'balance-history-current-balance-text',
    RECHARGE_BALANCE_BUTTON: 'balance-history-recharge-balance-button',
  },
  PAYMENT_BANK_CARD_CHOOSE_MODAL: {
    _: 'payment-bank-card-choose',
    AMOUNT_INPUT: 'payment-bank-card-input',
    WIRE_TRANSFER_OPTION: 'recharge-balance-modal-wire-transfer-option',
    SUBMIT: 'payment-bank-card-choose-modal-submit',
  },
  RECHARGE_BALANCE_MODAL: {
    _: 'recharge-balance-modal',
    AMOUNT_INPUT: 'recharge-balance-modal-amount-input',
    WIRE_TRANSFER_OPTION: 'recharge-balance-modal-wire-transfer-option',
    SUBMIT: 'recharge-balance-modal-submit',
  },
  MODAL: {
    DELETE: 'delete-modal-btn',
    TITLE: 'title-modal',
  },
  TABLE: {
    FOOTER_INFO: 'footer-info',
  },
}
