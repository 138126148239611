import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import messageImage from '../../../assets/images/message.png'
import { Tip } from '../Tip'

const SmsMailingLimitTipMessages = defineMessages({
  text: {
    id: 'SmsMailingLimitTipMessages.text',
    defaultMessage:
      'You can send no more than 300 messages per day. If you need more, please contact us at {email}',
  },
})

const SUPPORT_EMAIL = 'support@telecorn.com'

export const SmsMailingLimitTip = () => {
  const intl = useIntl()

  return (
    <Tip
      imageSource={messageImage}
      text={intl.formatMessage(SmsMailingLimitTipMessages.text, {
        email: <a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>,
      })}
    />
  )
}
