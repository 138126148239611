import React, { useMemo, useRef, useState } from 'react'
import clsx from 'clsx'
import { defineMessages, useIntl } from 'react-intl'
import { useNavigate } from 'react-router'
import { useModal } from '../../../../../../app/hooks/useModal'
import { ReactComponent as AvatarIcon } from '../../../../../../assets/icons/avatar.svg'
import { ReactComponent as MobileMenuIcon } from '../../../../../../assets/icons/burger.svg'
import { ReactComponent as CloseMobileMenuIcon } from '../../../../../../assets/icons/close-burger.svg'
import { ReactComponent as APIIcon } from '../../../../../../assets/icons/header-dropdown-api.svg'
import { ReactComponent as FAQIcon } from '../../../../../../assets/icons/header-dropdown-faq.svg'
import { ReactComponent as LogOutIcon } from '../../../../../../assets/icons/header-dropdown-logout.svg'
import { ReactComponent as OrderBillingIcon } from '../../../../../../assets/icons/header-dropdown-orders.svg'
import { ReactComponent as JivoChatCustomIcon } from '../../../../../../assets/icons/jivo-chat-icon.svg'
import { ReactComponent as SettingsIcon } from '../../../../../../assets/icons/settings.svg'
import { RoutePath } from '../../../../../../config/routes/constants/routePath'
import { Route } from '../../../../../../config/routes/enums/route'
import { useClickOutside } from '../../../../../../shared/lib/hooks/useClickOutside'
import { Button } from '../../../../../../shared/ui-kit-2/inputs/button/Button'
import { useAppDispatch, useAppSelector } from '../../../../../../store'
import { clearUser } from '../../../../../../store/reducers/user'
import { MODAL_TYPES } from '../../../../../modals/ModalsProvider'
import styles from './styles.module.scss'
import { useSiteVersion } from '../../../../../../app/hooks/useSiteVersion'

const UserHeaderMobileMenuMessage = defineMessages({
  accountDetails: {
    id: 'UserHeaderMobileMenuMessage.accountDetails',
    defaultMessage: 'Account Details',
  },
  ordersBilling: {
    id: 'UserHeaderMobileMenuMessage.ordersBilling',
    defaultMessage: 'Orders & Billing',
  },
  api: {
    id: 'UserHeaderMobileMenuMessage.api',
    defaultMessage: 'API',
  },
  settings: {
    id: 'UserHeaderMobileMenuMessage.settings',
    defaultMessage: 'Settings',
  },
  faq: {
    id: 'UserHeaderMobileMenuMessage.faq',
    defaultMessage: 'FAQ',
  },
  support: {
    id: 'UserHeaderMobileMenuMessage.support',
    defaultMessage: 'Support',
  },
  logOut: {
    id: 'UserHeaderMobileMenuMessage.logOut',
    defaultMessage: 'Log out',
  },
  activateDevice: {
    id: 'UserHeaderMobileMenuMessage.activateDevice',
    defaultMessage: 'Activate Device',
  },
  buyDevice: {
    id: 'UserHeaderMobileMenuMessage.buyDevice',
    defaultMessage: 'Buy Device',
  },
})

export const UserHeaderMobileMenu = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const { firstName, lastName, companyName, email } = useAppSelector(
    (state) => state.user,
  )
  const intl = useIntl()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { handleOpenModal } = useModal()
  const ref = useRef<HTMLDivElement>(null)
  useClickOutside(ref, () => setIsOpen(false))
  const { siteVersion } = useSiteVersion()

  const nickname = useMemo(() => {
    if (companyName) return companyName
    else if (firstName && lastName)
      return `${firstName} ${lastName?.slice(0, 1)}.`
    else return email
  }, [firstName, lastName, companyName, email])

  const openDropdown = () => setIsOpen(true)

  const closeDropdown = () => {
    setIsOpen(false)
  }

  const wrapCloseDropdownAction = (fn: any) => {
    fn()
    setIsOpen(false)
  }

  //TODO CHANGE ON SUPPORT
  const handleOpenChat = () => {
    closeDropdown()
    //@ts-ignore
    if (window.jivo_api) {
      //@ts-ignore
      window.jivo_api.open()
    }
  }

  const toAccountDetailsPage = () => {
    wrapCloseDropdownAction(() => navigate(RoutePath[Route.Profile]))
  }

  const toOrdersPage = () => {
    wrapCloseDropdownAction(() => navigate(RoutePath[Route.Orders]))
  }

  const toApiPage = () => {
    wrapCloseDropdownAction(() => navigate(RoutePath[Route.Api]))
  }

  const handleLogout = () => {
    wrapCloseDropdownAction(() => dispatch(clearUser()))
  }

  const toBuyNewDevice = () => {
    setIsOpen(false)
    handleOpenModal({
      type: MODAL_TYPES.BUY_NEW_DEVICE,
    })
  }

  const toActivateDevices = () => {
    wrapCloseDropdownAction(() => navigate(RoutePath[Route.ActivateDevice]))
  }

  const menuDropdown = (
    <div className={styles.Dropdown}>
      <div
        className={styles.Dropdown__Content}
        onClick={(e) => e.stopPropagation()}
        ref={ref}
      >
        <button className={styles.Dropdown__Closer} onClick={closeDropdown}>
          <CloseMobileMenuIcon />
        </button>

        <div className={styles.Avatar}>
          <div className={styles.AvatarCircle}>
            <AvatarIcon />
          </div>
          <div className={styles.Nickname}>{nickname}</div>
        </div>

        <div className={styles.Border}></div>

        <div className={styles.MainLinksWrapper}>
          <div className={styles.MainLinkItem} onClick={toAccountDetailsPage}>
            <AvatarIcon />
            <div>
              {intl.formatMessage(UserHeaderMobileMenuMessage.accountDetails)}
            </div>
          </div>
          <div className={styles.MainLinkItem} onClick={toOrdersPage}>
            <OrderBillingIcon />
            <div>
              {intl.formatMessage(UserHeaderMobileMenuMessage.ordersBilling)}
            </div>
          </div>
          <div className={styles.MainLinkItem} onClick={toApiPage}>
            <APIIcon />
            <div>{intl.formatMessage(UserHeaderMobileMenuMessage.api)}</div>
          </div>
        </div>

        <div className={styles.SecondLinksWrapper}>
          {/* <div className={styles.SecondLinkItem}>
            <SettingsIcon/>
            <div>
              {intl.formatMessage(UserHeaderMobileMenuMessage.settings)}
            </div>
          </div>*/}

          {/*<div className={styles.SecondLinkItem}>
            <FAQIcon/>
            <div>
              {intl.formatMessage(UserHeaderMobileMenuMessage.faq)}
            </div>
          </div>*/}

          {siteVersion !== 'ru' && (
            <div
              className={clsx(styles.SecondLinkItem, styles.JivoChat)}
              onClick={handleOpenChat}
            >
              <JivoChatCustomIcon />
              <div>{intl.formatMessage(UserHeaderMobileMenuMessage.support)}</div>
            </div>
          )}
          <div
            className={clsx(styles.SecondLinkItem, styles.LogOut)}
            onClick={handleLogout}
          >
            <LogOutIcon />
            <div>{intl.formatMessage(UserHeaderMobileMenuMessage.logOut)}</div>
          </div>
        </div>

        <div className={styles.DeviceActions}>
          <Button
            text={intl.formatMessage(
              UserHeaderMobileMenuMessage.activateDevice,
            )}
            variant={'greenTextOutlined'}
            additionalClassNames={[styles.ActivateDevice]}
            onClick={toActivateDevices}
          />
          <Button
            variant={'green'}
            text={intl.formatMessage(UserHeaderMobileMenuMessage.buyDevice)}
            onClick={toBuyNewDevice}
          />
        </div>
      </div>
    </div>
  )

  const menuButtonContent = isOpen ? <></> : <MobileMenuIcon />

  return (
    <>
      <div className={styles.BurgerMenu} onClick={openDropdown}>
        {menuButtonContent}
      </div>

      {isOpen && menuDropdown}
    </>
  )
}
