import React, { useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { ReactComponent as LogoVector } from '../../assets/icons/logo.svg'
import { AuthRoutePath } from '../../config/routes/constants/authRoutePath'
import { RoutePath } from '../../config/routes/constants/routePath'
import { AuthRoute } from '../../config/routes/enums/authRoute'
import { Route } from '../../config/routes/enums/route'
import { useAppSelector } from '../../store'
import { BannerDebt } from './components/banner/BannerDebt'
import { SidebarPages } from './components/sidebar-pages/SidebarPages'
import styles from './styles.module.scss'

const SidebarNotShownRoutePathGroup = [
  Route.Home,
  Route.PurchaseProcess,
  Route.TermsOfUse,
  Route.PrivacyPolicy,
  Route.LegalNotice,
  Route.FAQ
].map((route) => RoutePath[route])

const SidebarNotShownAuthRoutePathGroup = [
  AuthRoute.SignIn,
  AuthRoute.SignUp,
  AuthRoute.RecoverPassword,
  AuthRoute.ThirdParty,
  AuthRoute.ThirdParty,
].map((route) => AuthRoutePath[route])

const SidebarNotShownPathGroup = [
  ...SidebarNotShownRoutePathGroup,
  ...SidebarNotShownAuthRoutePathGroup,
]

export const Sidebar = () => {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const isAuthenticated = useAppSelector((state) => state.user.isAuthenticated)

  const isSidebarHidden = useMemo(
    () => SidebarNotShownPathGroup.some((path) => path === pathname),
    [pathname],
  )

  const toHome = () => navigate(RoutePath[Route.Home])

  if (isSidebarHidden || !isAuthenticated) return null

  return (
    <div className={styles.Container}>
      <div className={styles.Content}>
        <LogoVector className={styles.Logo} onClick={toHome} />

        <SidebarPages />
        <BannerDebt />
      </div>
    </div>
  )
}
