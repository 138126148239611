import React, { FC } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { DongleListResponse } from '../../../../../../sdk/datagates/types/dongle/_crud/list'
import {
  CloudTabs,
  CloudTabType,
} from '../../../../../../shared/ui-kit-2/navigation/cloud-tabs/CloudTabs'
import { CallList } from '../callsListDesktopPage/call-list/CallList'
import { CallRedirectionList } from '../callsListDesktopPage/call-redirection-list/CallRedirectionList'
import styles from './styles.module.scss'

interface CallsListMobilePageProps {
  devices: DongleListResponse['dongles']
  activeDevicesLoading: boolean
}

enum CALLS_PAGE_TABS {
  CALLS,
  REDIRECTIONS,
}

const CallsListMobilePageMessages = defineMessages({
  callsTab: {
    id: 'CallsListMobilePageMessages.callsTab',
    defaultMessage: 'Calls',
  },
  redirectionTab: {
    id: 'CallsListMobilePageMessages.redirectionTab',
    defaultMessage: 'Redirection Rules',
  },
})

export const CallsListMobilePage: FC<CallsListMobilePageProps> = (props) => {
  const { devices, activeDevicesLoading } = props

  const intl = useIntl()

  const cloudTabOptions: CloudTabType[] = [
    {
      id: CALLS_PAGE_TABS.CALLS.toString(),
      label: intl.formatMessage(CallsListMobilePageMessages.callsTab),
      render: (
        <CallList
          activeDevices={devices}
          activeDevicesLoading={activeDevicesLoading}
        />
      ),
    },
    {
      id: CALLS_PAGE_TABS.REDIRECTIONS.toString(),
      label: intl.formatMessage(CallsListMobilePageMessages.redirectionTab),
      render: <CallRedirectionList activeDevices={devices} />,
    },
  ]

  return (
    <div className={styles.Container}>
      <CloudTabs items={cloudTabOptions} />
    </div>
  )
}
