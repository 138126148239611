import React, { FC } from 'react'
import { DongleListResponse } from '../../../../../../sdk/datagates/types/dongle/_crud/list'
import { CallList } from './call-list/CallList'
import { CallRedirectionList } from './call-redirection-list/CallRedirectionList'
import styles from './styles.module.scss'

interface CallsListDesktopPageProps {
  devices: DongleListResponse['dongles']
  loading: boolean
}

export const CallsListDesktopPage: FC<CallsListDesktopPageProps> = (props) => {
  const { devices, loading } = props

  return (
    <div className={styles.Container}>
      <CallList activeDevices={devices} activeDevicesLoading={loading} />
      <CallRedirectionList activeDevices={devices} />
    </div>
  )
}
