import { Navigate, Route as ReactRoute, Routes } from 'react-router-dom'
import { RoutePath } from '../config/routes/constants/routePath'
import { Route } from '../config/routes/enums/route'
import { routes } from '../config/routes/routes'
import { AccessRoute } from './AccessRoute'
import {useSiteVersion} from "../app/hooks/useSiteVersion";

const RUS_ROOTS_BLACKLIST = [
  Route.PaymentSuccess,
  Route.PaymentFail,
  Route.AddCardSuccess,
  Route.Orders,
  Route.ActivateDevice,
  Route.PrivacyPolicy,
  Route.TermsOfUse,
  Route.LegalNotice,
  Route.FAQ
] as Route[]

export const RootRoutes = () => {
  const { siteVersion } = useSiteVersion()
  const routesArray = Object.values(routes);
  const localizedRoutes = siteVersion === 'ru'
    ? routesArray.filter(({ route }) => !RUS_ROOTS_BLACKLIST.includes(route))
    : routesArray;

  return (
    <Routes>
      {localizedRoutes.map((r) => (
        <ReactRoute
          key={r.route}
          path={r.path}
          element={<AccessRoute key={r.route} route={r} />}
        />
      ))}

      <ReactRoute
        path={'*'}
        element={<Navigate to={RoutePath[Route.NotFound]} replace={true} />}
      />
    </Routes>
  )
}
