import React, { FC, ReactNode } from 'react'
import clsx from 'clsx'
import { Loader } from '../../../ui-kit/data-display/loader/Loader'
import { ReactComponent as ActivateIcon } from './assets/icons/activate.svg'
import { ReactComponent as AddIcon } from './assets/icons/add.svg'
import { ReactComponent as BuyIcon } from './assets/icons/buy.svg'
import { ReactComponent as CallIcon } from './assets/icons/call.svg'
import { ReactComponent as CancelIcon } from './assets/icons/cancel.svg'
import { ReactComponent as CaretRightIcon } from './assets/icons/caret-right.svg'
import { ReactComponent as EditIcon } from './assets/icons/edit.svg'
import { ReactComponent as GoogleIcon } from './assets/icons/google.svg'
import { ReactComponent as ReplyIcon } from './assets/icons/reply.svg'
import { ReactComponent as TrashIcon } from './assets/icons/trash.svg'
import { ReactComponent as UserPlusIcon } from './assets/icons/user-plus.svg'
import styles from './styles.module.scss'

const BUTTON_VARIANT_LIST = {
  green: 'green',
  gray: 'gray',
  white: 'white',
  danger: 'danger',
  blackTextBlackBorderOutlined: 'blackTextBlackBorderOutlined',
  blackTextGreenBorderOutlined: 'blackTextGreenBorderOutlined',
  greenTextOutlined: 'greenTextOutlined',
  greenTextFilled: 'greenTextFilled',
} as const

const BUTTON_SIZE_LIST = {
  default: 'default',
  small: 'small',
} as const

const BUTTON_ICON_LIST = {
  google: 'google',
  facebook: 'facebook',
  call: 'call',
  trash: 'trash',
  edit: 'edit',
  userPlus: 'user-plus',
  cancel: 'cancel',
  reply: 'reply',
  add: 'add',
  buy: 'buy',
  activate: 'activate',
  caret: 'caret',
} as const

export type BUTTON_ICON_LIST_TYPE =
  (typeof BUTTON_ICON_LIST)[keyof typeof BUTTON_ICON_LIST]

const ICONS = {
  [BUTTON_ICON_LIST.google]: <GoogleIcon />,
  [BUTTON_ICON_LIST.facebook]: null,
  [BUTTON_ICON_LIST.call]: <CallIcon />,
  [BUTTON_ICON_LIST.trash]: <TrashIcon />,
  [BUTTON_ICON_LIST.edit]: <EditIcon />,
  [BUTTON_ICON_LIST.userPlus]: <UserPlusIcon />,
  [BUTTON_ICON_LIST.cancel]: <CancelIcon />,
  [BUTTON_ICON_LIST.reply]: <ReplyIcon />,
  [BUTTON_ICON_LIST.add]: <AddIcon />,
  [BUTTON_ICON_LIST.buy]: <BuyIcon />,
  [BUTTON_ICON_LIST.activate]: <ActivateIcon />,
  [BUTTON_ICON_LIST.caret]: <CaretRightIcon />,
}

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  text: string | ReactNode
  variant?: (typeof BUTTON_VARIANT_LIST)[keyof typeof BUTTON_VARIANT_LIST]
  size?: (typeof BUTTON_SIZE_LIST)[keyof typeof BUTTON_SIZE_LIST]
  icon?: BUTTON_ICON_LIST_TYPE
  loading?: boolean
  additionalClassNames?: string[]
  testId?: string
  reverse?: boolean
}

export const Button: FC<ButtonProps> = (props) => {
  const {
    text,
    icon,
    variant = BUTTON_VARIANT_LIST.green,
    size = BUTTON_SIZE_LIST.default,
    loading,
    additionalClassNames = [],
    testId,
    reverse,
    ...otherProps
  } = props

  const content = loading ? (
    <Loader color={'#080E16'} isSmall={true} />
  ) : (
    <span
      className={clsx(
        styles.Button__IconContent,
        reverse && styles.Button__IconContent_reverse,
      )}
    >
      {icon && ICONS[icon]}
      <span className={icon && styles.Button__IconText}>{text}</span>
    </span>
  )

  return (
    <button
      type={'button'}
      className={clsx(
        styles.Button,
        variant === BUTTON_VARIANT_LIST.green && styles.Button_green,
        variant === BUTTON_VARIANT_LIST.gray && styles.Button_gray,
        variant === BUTTON_VARIANT_LIST.white && styles.Button_white,
        variant === BUTTON_VARIANT_LIST.danger && styles.Button_danger,

        variant === BUTTON_VARIANT_LIST.blackTextBlackBorderOutlined &&
          styles.Button_outlined,

        variant === BUTTON_VARIANT_LIST.blackTextGreenBorderOutlined &&
          styles.Button_outlined,
        variant === BUTTON_VARIANT_LIST.blackTextGreenBorderOutlined &&
          styles.Button_outlined_greenBorder,

        variant === BUTTON_VARIANT_LIST.greenTextOutlined &&
          styles.Button_outlined,
        variant === BUTTON_VARIANT_LIST.greenTextOutlined &&
          styles.Button_greenText,

        variant === BUTTON_VARIANT_LIST.greenTextFilled &&
          styles.Button_greenText,

        size === BUTTON_SIZE_LIST.small && styles.Button_small,
        ...additionalClassNames,
      )}
      data-test-id={testId}
      {...otherProps}
    >
      {content}
    </button>
  )
}
