import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { BuyDeviceButton } from '../../../custom-buttons/buy-device-button/BuyDeviceButton'
import styles from './styles.module.scss'

const NoPurchasedDevicesWifiMessages = defineMessages({
  title: {
    id: 'NoPurchasedDevicesWifiMessages.title',
    defaultMessage: 'Buy a Telecorn modem to use it as a Wi-Fi Hotspot',
  },
  subTitle: {
    id: 'NoPurchasedDevicesWifiMessages.subTitle',
    defaultMessage:
      'Experience rapid 4G LTE internet access, ensuring smooth and reliable connectivity for all your business needs.',
  },
})

export const NoPurchasedDevicesWifi = () => {
  const intl = useIntl()

  return (
    <div className={styles.Container}>
      <div className={styles.Description}>
        <div className={styles.Title}>
          {intl.formatMessage(NoPurchasedDevicesWifiMessages.title)}
        </div>
        <div className={styles.Subtitle}>
          {intl.formatMessage(NoPurchasedDevicesWifiMessages.subTitle)}
        </div>
        <div className={styles.Button}>
          <BuyDeviceButton />
        </div>
      </div>
      <div className={styles.VideoContainer}>
        <video
          autoPlay
          muted
          loop
          controls={false}
          className={styles.Video}
          playsInline
        >
          <source src="/videos/wifi-hotspot.mp4" type="video/mp4" />
        </video>
        <div className={styles.Mask}></div>
      </div>
    </div>
  )
}
