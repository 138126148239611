import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useNavigate } from 'react-router'
import { RoutePath } from '../../../config/routes/constants/routePath'
import { Route } from '../../../config/routes/enums/route'
import { PageWrapper } from '../../../layouts/page-wrapper/PageWrapper'
import { Button } from '../../../shared/ui-kit-2/inputs/button/Button'
import styles from './styles.module.scss'

const NotFoundPageMessages = defineMessages({
  toHome: {
    id: 'NotFoundPageMessages.toHome',
    defaultMessage: 'To home',
  },
})

export const NotFoundPage = () => {
  const navigate = useNavigate()
  const intl = useIntl()

  const toHome = () => navigate(RoutePath[Route.Home])

  return (
    <PageWrapper>
      <div className={styles.Container}>
        <div className={styles.Content}>
          <div className={styles.Title}>404</div>
          <Button
            text={intl.formatMessage(NotFoundPageMessages.toHome)}
            onClick={toHome}
          />
        </div>
      </div>
    </PageWrapper>
  )
}
