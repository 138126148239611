import React from 'react'
import clsx from 'clsx'
import { defineMessages, useIntl } from 'react-intl'
import { useModal } from '../../../../../app/hooks/useModal'
import { ReactComponent as ArrowRightIcon } from '../../../../../assets/icons/arrow-right.svg'
import { ReactComponent as ReceivedIcon } from '../../../../../assets/icons/received-mini.svg'
import { ReactComponent as SentIcon } from '../../../../../assets/icons/sent-mini.svg'
import { ReactComponent as TrashIcon } from '../../../../../assets/icons/trash.svg'
import { CallListResponse } from '../../../../../sdk/datagates/types/call/_crud/list'
import { formatPhoneNumber } from '../../../../../sdk/formatters/format-phone-number'
import { useCallStatuses } from '../../../../../sdk/hooks/use-call-statuses/useCallStatuses'
import { CallType } from '../../../../../sdk/hooks/use-calls/useCalls'
import { formatDate } from '../../../../../shared/lib/utils/date-utils/formatDate'
import { RowGroupType } from '../../../../../shared/ui-kit-2/data-display/table/Table'
import { CallTypeBadge } from '../../../../custom-badges/call-type-badge/CallTypeBadge'
import { MODAL_TYPES } from '../../../../modals/ModalsProvider'
import styles from './styles.module.scss'

type CallGroupsType = {
  [key: string]: CallListResponse['calls']
}

const CallTableRowsMessages = defineMessages({
  groupHeaderSent: {
    id: 'CallTableRows.groupHeaderSent',
    defaultMessage: 'Sent: {count}',
  },
  groupHeaderReceived: {
    id: 'CallTableRows.groupHeaderReceived',
    defaultMessage: 'Received: {count}',
  },
  deletingItemName: {
    id: 'CallTableRows.deletingItemName',
    defaultMessage: 'the call',
  },
})

export const CallTableRows = (
  calls: CallListResponse['calls'],
  handleDeleteCall: (dongleId: number, callId: number) => Promise<void>,
): RowGroupType[] => {
  const intl = useIntl()
  const { handleOpenModal } = useModal()
  const { getCallStatusStickerComponent } = useCallStatuses()

  const callGroups = calls.reduce<CallGroupsType>((acc, call, idx) => {
    const date = formatDate(call.createdAt, 0, 'date')
    if (acc.hasOwnProperty(date)) acc[date as keyof typeof acc].push(call)
    else acc[date as keyof typeof acc] = [call]

    return acc
  }, {})

  return Object.entries(callGroups).map<RowGroupType>(([date, calls]) => {
    return {
      groupHeader: (
        <div className={styles.Header}>
          <div className={styles.Header__date}>{date}</div>
          <div className={styles.Header__sentAndReceived}>
            <div className={styles.Header__sentAndReceived__item}>
              <SentIcon />
              <div>
                {intl.formatMessage(CallTableRowsMessages.groupHeaderSent, {
                  count: calls.filter((c) => c.callTypeId === CallType.OUTGOING)
                    .length,
                })}
              </div>
            </div>

            <div className={styles.Header__sentAndReceived__item}>
              <ReceivedIcon />
              <div>
                {intl.formatMessage(CallTableRowsMessages.groupHeaderReceived, {
                  count: calls.filter((c) => c.callTypeId === CallType.INCOMING)
                    .length,
                })}
              </div>
            </div>
          </div>
        </div>
      ),
      rows: calls.map((call) => {
        const isDeviceSender = [CallType.OUTGOING, CallType.DELAYED].includes(
          call.callTypeId,
        )

        const contactSenderName = isDeviceSender
          ? call.dongleName
          : call.contact
            ? call.contact.name
            : ''
        const contactReceiverName = isDeviceSender
          ? call.contact
            ? call.contact.name
            : ''
          : call.dongleName

        const handleDelete = () =>
          handleOpenModal({
            type: MODAL_TYPES.DELETE_ITEM,
            props: {
              deletingItemName: intl.formatMessage(
                CallTableRowsMessages.deletingItemName,
              ),
              handleSubmit: () => handleDeleteCall(call.dongleId, call.callId),
            },
          })

        return [
          <CallTypeBadge type={call.callTypeId} />,
          <div className={styles.Time}>
            {formatDate(call.createdAt, 0, 'time')}
          </div>,
          <div>{getCallStatusStickerComponent(call.callStatusId)}</div>,
          <div className={styles.Contact}>
            <div
              className={clsx(
                styles.Contact__name,
                isDeviceSender && styles.Contact__name_device,
              )}
            >
              {contactSenderName}
            </div>
            <div className={styles.Contact__phone}>
              {formatPhoneNumber(call.numberCaller)}
            </div>
          </div>,
          <ArrowRightIcon />,
          <div className={styles.Contact}>
            <div
              className={clsx(
                styles.Contact__name,
                !isDeviceSender && styles.Contact__name_device,
              )}
            >
              {contactReceiverName}
            </div>
            <div className={styles.Contact__phone}>
              {formatPhoneNumber(call.numberReceiver)}
            </div>
          </div>,
          <div className={styles.DeleteBtn} tabIndex={0} onClick={handleDelete}>
            <TrashIcon />
          </div>,
        ]
      }),
    }
  })
}
