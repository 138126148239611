import React, { useState } from 'react'
import clsx from 'clsx'
import { useIntl } from 'react-intl'
import { useModal } from '../../../app/hooks/useModal'
import { useTariffPackages } from '../../../sdk/hooks/use-tariff-packages/useTariffPackages'
import { Drawer } from '../../../shared/ui-kit-2/data-display/drawer/Drawer'
import { LoadingContent } from '../../loading-content/LoadingContent'
import styles from './styles.module.scss'
import {TextButton} from "../../../shared/ui-kit-2/inputs/text-button/TextButton";
import {usePayDevice} from "../_hooks/use-pay-device/usePayDevice";
import {useDevice} from "../../../sdk/hooks/use-device/useDevice";
import {RedirectionsDetails} from "./components/redirectionsDetails/RedirectionsDetails";
import {DeviceDetailsModalMessages} from "./messages/DeviceDetailsModalMessages";
import {UssdCard} from "./components/ussdCard/UssdCard";
import {NetworkInformation} from "./components/networkInformation/NetworkInformation";
import {Subscriptions} from "./components/subscriptions/Subscriptions";
import {WifiDetails} from "./components/wifiDetails/WifiDetails";
import {ModemInfo} from "./components/modemInfo/ModemInfo";
import {UsePayOrChangeSubscription} from "../_hooks/use-pay-or-change-subscription/usePayOrChangeSubscription";
import {useNetworkInformation} from "../../../sdk/hooks/use-network-information/useNetworkInformation";


//TODO REFACTORING WITH DECOMPOSITION
//TODO change messages
//TODO Hide Reboot wait BA
export const DeviceDetailsModal = () => {
  const { handleHideModal, props } = useModal()
  const {
    deviceId,
    handleSwitchHotspot,
    handleFetchDevices,
    handleDeleteDevice,
  }: {
    deviceId: number
    handleFetchDevices: () => Promise<void>
    handleDeleteDevice: (dongleId: number) => Promise<void>
    handleSwitchHotspot: (dongleId: number, enabled: boolean) => Promise<void>
  } = props;
  const intl = useIntl()
  const {
    device,
    loading,
    handleFetch
  } = useDevice({deviceId})
  const [isPayOrChangeSubscription, setIsPayOrChangeSubscription] = useState<boolean>(false);
  const { getTariffPackageTranslation, getTariffPackagePrice } = useTariffPackages();

  const {
    component,
    setIsPayDeviceOpen,
    tariffPackageId,
    isOpen: isOpenPayDevice,
  } = usePayDevice({
    getTariffPackagePrice,
    getTariffPackageTranslation,
    device,
    setIsPayOrChangeSubscription,
  });

  const {component: componentSubscription} = UsePayOrChangeSubscription({
    isPayOrChangeSubscription,
    setIsPayOrChangeSubscription,
    tariffPackageId,
    setIsPayDeviceOpen,
    dongleId: deviceId,
  })

  const { networkInformation, networkInformationLoading } = useNetworkInformation(device)

  const isTariffPackageEnabled = !device?.isTariffPackageActive;

  const content = device ? <>
    <div className={styles.DeviceDetails}>
      <ModemInfo
        device={device}
        handleFetchDevices={handleFetchDevices}
        handleFetchDevice={handleFetch}
        handleDeleteDevice={handleDeleteDevice}
        isTariffPackageEnabled={isTariffPackageEnabled}
      />
      <WifiDetails
        device={device}
        handleSwitchHotspot={handleSwitchHotspot}
        handleFetchDevices={handleFetchDevices}
        isTariffPackageEnabled={isTariffPackageEnabled}
      />

      <div className={
        clsx(styles.Card, styles.Card_subscription,
        isTariffPackageEnabled && styles.ErrorDate)
        }
      >
        <div className={styles.Header}>
          <div className={styles.Title}>
            {intl.formatMessage(DeviceDetailsModalMessages.subscription)}
          </div>
          <TextButton
            icon={'edit'}
            text={intl.formatMessage(DeviceDetailsModalMessages.manage)}
            additionalClassNames={[styles.EditButton]}
            onClick={() => setIsPayDeviceOpen(true)}
          />
        </div>
        <div className={styles.Divider} />
        <Subscriptions
          device={device}
          getTariffPackagePrice={getTariffPackagePrice}
          getTariffPackageTranslation={getTariffPackageTranslation}
          tariffPackageEnable={isTariffPackageEnabled}
        />
      </div>

      <div className={clsx(styles.Card, styles.Card_networkInformation)}>
        <div className={styles.Header}>
          <div className={clsx(styles.Title, isTariffPackageEnabled && styles.ErrorSubscriptionTitle)}>
            {intl.formatMessage(
              DeviceDetailsModalMessages.networkInformation,
            )}
          </div>
        </div>
        <div className={styles.Divider} />
        <NetworkInformation
          networkInformation={networkInformation}
          networkInformationLoading={networkInformationLoading}
          device={device}
          isTariffPackageEnabled={isTariffPackageEnabled}
        />
      </div>

      <div className={
        clsx(styles.Card,
          styles.Card_redirects,
          isTariffPackageEnabled && styles.ErrorSubscription
        )}
      >
        <div className={styles.Header}>
          <div className={styles.Title}>
            {intl.formatMessage(DeviceDetailsModalMessages.redirects)}
          </div>
        </div>
        <div className={styles.Divider} />
        <RedirectionsDetails
          deviceId={deviceId}
          handleFetchDevices={handleFetchDevices}
          isTariffPackageEnabled={isTariffPackageEnabled}
        />
      </div>

      <UssdCard
        isTariffPackageEnabled={isTariffPackageEnabled}
      />
    </div>
    {component}
    {isPayOrChangeSubscription && componentSubscription}
  </> : <></>

  return (
    <Drawer
      isOpen={true}
      close={handleHideModal}
      title={intl.formatMessage(DeviceDetailsModalMessages.title)}
      limitedWidth={false}
      additionalClassNames={[clsx((isOpenPayDevice || isPayOrChangeSubscription) && styles.ScrollOff)]}
      fullWidth
    >
     <LoadingContent loading={loading} content={content}/>
    </Drawer>
  )
}
