import React, { FC } from 'react'
import { Form } from 'react-final-form'
import { defineMessages, useIntl } from 'react-intl'
import { useModal } from '../../../app/hooks/useModal'
import { CommonButtonMessages } from '../../../config/intl/common-messages/common-button-messages/CommonButtonMessages'
import { InputField } from '../../../shared/lib/form/form-field-adapters/v2/input-field/InputField'
import {
  composeValidators,
  useFormRules,
} from '../../../shared/lib/form/form-rules'
import { Drawer } from '../../../shared/ui-kit-2/data-display/drawer/Drawer'
import { Button } from '../../../shared/ui-kit-2/inputs/button/Button'
import { LC } from '../../../tests/e2e/locators'
import styles from './styles.module.scss'

type Props = {
  handleClose?: () => void
}

const WifiSettingsModalMessages = defineMessages({
  hotspotNameLabel: {
    id: 'WifiSettingsModalMessages.hotspotName',
    defaultMessage: 'WIFI name',
  },
  hotspotNamePlaceholder: {
    id: 'WifiSettingsModalMessages.hotspotPlaceholder',
    defaultMessage: 'Enter name',
  },
  hotspotPasswordLabel: {
    id: 'WifiSettingsModalMessages.hotspotPasswordLabel',
    defaultMessage: 'WIFI password',
  },
  hotspotPasswordPlaceholder: {
    id: 'WifiSettingsModalMessages.hotspotPasswordPlaceholder',
    defaultMessage: 'Enter new password',
  },
})

export const WifiSettingsModal: FC<Props> = () => {
  const intl = useIntl()
  const { ruleRequired, ruleHotspotName, ruleHotspotPassword } = useFormRules()
  const { handleHideModal, props } = useModal()

  const { handleSubmit, deviceId, deviceName, hotspotName, hotspotPassword } =
    props

  return (
    <Drawer
      title={deviceName}
      isOpen={true}
      close={handleHideModal}
      additionalClassNames={[styles.DrawerAdditional]}
      fullWidth
    >
      <Form
        onSubmit={handleSubmit}
        initialValues={{
          deviceId,
          hotspotName,
          hotspotPassword,
        }}
        render={({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit} className={styles.Content}>
            <InputField
              name={'hotspotName'}
              validationNeeded={false}
              validate={composeValidators(ruleRequired(), ruleHotspotName())}
              label={intl.formatMessage(
                WifiSettingsModalMessages.hotspotNameLabel,
              )}
              placeholder={intl.formatMessage(
                WifiSettingsModalMessages.hotspotNamePlaceholder,
              )}
              markAsRequired={true}
              testId={LC.WIFI_SETTINGS.NAME}
            />

            <InputField
              name={'hotspotPassword'}
              validationNeeded={false}
              validate={composeValidators(
                ruleRequired(),
                ruleHotspotPassword(),
              )}
              label={intl.formatMessage(
                WifiSettingsModalMessages.hotspotPasswordLabel,
              )}
              placeholder={intl.formatMessage(
                WifiSettingsModalMessages.hotspotPasswordPlaceholder,
              )}
              markAsRequired={true}
              testId={LC.WIFI_SETTINGS.PASSWORD}
            />
            <div className={styles.Actions}>
              <Button
                onClick={handleHideModal}
                text={intl.formatMessage(CommonButtonMessages.cancel)}
                variant="blackTextBlackBorderOutlined"
                additionalClassNames={[styles.SubmitAdditional]}
                testId={LC.WIFI_SETTINGS.CANCEL}
              />
              <Button
                type={'submit'}
                text={intl.formatMessage(CommonButtonMessages.save)}
                additionalClassNames={[styles.SubmitAdditional]}
                disabled={submitting}
                loading={submitting}
                testId={LC.WIFI_SETTINGS.SUBMIT}
              />
            </div>
          </form>
        )}
      />
    </Drawer>
  )
}
